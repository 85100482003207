// Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import '../Footer.css'; // Create this CSS file for styling the footer

const Footer = () => {
  return (
<footer className="footer">
  <div className="container w-container">
    <div className="footer-links">
      <Link to="/terms-and-conditions" className="footer-link">Terms and Conditions</Link>
      <span className="footer-divider">|</span>
      <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
    </div>
    <p
      style={{
        fontSize: "13px", 
        color: "#666", 
        marginTop: "10px",
      }}
    >
      Copyright © 2024 Creators Reach LLC, All rights reserved
    </p>
  </div>
</footer>


  
  );
};

export default Footer;
