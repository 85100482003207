// PublicNavigation.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const PublicNavigation = ({ isActive, toggleMenu }) => {
  const navigate = useNavigate(); // Use navigate for redirection

  const handleLogin = () => {
    toggleMenu(); // Close the menu if open
    navigate('/login'); // Redirect to the login page
  };

  return (
    <div className="nav-container">
      <ul className="nav-header-list w-list-unstyled">
        <li className="nav-list-item">
          <Link to="/" className={`w-nav-link ${isActive('/')}`} onClick={toggleMenu}>
            Home
          </Link>
        </li>
        <li className="nav-list-item">
          <Link to="/how-it-works" className={`w-nav-link ${isActive('/how-it-works')}`} onClick={toggleMenu}>
            How it works
          </Link>
        </li>
        <li className="nav-list-item">
          <Link to="/pricing" className={`w-nav-link ${isActive('/pricing')}`} onClick={toggleMenu}>
            Pricing
          </Link>
        </li>
        <li className="nav-list-item">
          <Link to="/about" className={`w-nav-link ${isActive('/about')}`} onClick={toggleMenu}>
            About
          </Link>
          </li>
        <li className="nav-list-item">
          <Link to="/contact" className={`w-nav-link ${isActive('/contact')}`} onClick={toggleMenu}>
            Contact
          </Link>
        </li>
        <li className="nav-list-item">
          <button onClick={handleLogin} className="w-nav-link button-2">Log in</button> {/* Styled as the logout button */}
        </li>
      </ul>
    </div>
  );
};

export default PublicNavigation;
