import React, { useEffect } from 'react';
import Navigation from './Navigation';

const TermsAndServices = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'termly-jssdk';
    script.src = 'https://app.termly.io/embed-policy.min.js';
    document.body.appendChild(script);

    script.onload = () => {
      // Apply custom styles to the Termly embed content after it loads
      const style = document.createElement('style');
      style.innerHTML = `
        [name="termly-embed"] * {
          color: #ffffff !important; /* Force all text within the embed to be white */
        }
      `;
      document.head.appendChild(style);
    };

    return () => {
      if (document.getElementById('termly-jssdk')) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <div>
      <Navigation />

      {/* Terms and Services Background */}
      <div
        className="hero-section-bg-image how-it-works-section"
        style={{
          paddingTop: '220px', // Push content down to clear the navigation
        }}
      >
        <div className="container w-container">
          <div
            className="terms-content"
            name="termly-embed"
            data-id="4b1cb5de-648e-474d-ab5e-47c15c6e858b"
            style={{
              padding: '40px',
              textAlign: 'left',
              backgroundColor: '#cccccc', // Optional dark overlay for clarity
              borderRadius: '10px',
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndServices;
