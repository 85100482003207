import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../Subscriptions.css';

const Failure = () => {
    const navigate = useNavigate();

    const handleRetry = () => {
        navigate('/subscriptions'); // Redirect back to subscriptions page
    };

    return (
        <div className="hero-section-bg-image price">
            <div className="container w-container">
                <div className="inner-container-center-540px">
                    <div className="center-text">
                        <div className="dark-color-text">
                            <p className="display-1 white">
                                Subscription Failed
                            </p>
                        </div>
                    </div>
                    <p className="message-text white">
                        There was an error processing your subscription. Please try again or contact support if the issue persists.
                    </p>
                    <div className="button-center">
                        <button className="btn-primary w-button" onClick={handleRetry}>
                            Retry Subscription
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Failure;
