import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analytics, logEvent } from "../firebase"; // Ensure the path to firebase.js is correct

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, "page_view", { page_path: location.pathname });
  }, [location]);

  return null; // Does not render any UI
};

export default TrackPageView;
