import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { useState } from 'react';
import Navigation from '../components/Navigation';
import '../Contact.css';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  return (
    <div>
        <Helmet>
        <title>Contact</title>
        <meta name="viewport" content="width=500" />
        </Helmet>
      <Navigation /> {/* Add the navigation here */}
      <div className="page-wrapper">
      <div className="page-wrapper">
      <div className="position-relative">
          <div className="hero-section-bg-image about enhanced-bg">
            <div className="container w-container">
              <div className="mg-bottom-60px">
                <div className="inner-container-center-540px">
                  {/* Fancy Card */}
                  <div className="contact-card fancy-card">
                    <div className="center-text">
                      <div className="mg-bottom-16px">
                        <h3 className="title-large">Connect with Our Team</h3>
                      </div>
                      <h2 className="title-main">We’re Here to Assist You!</h2>
                      <p className="paragraph grey">
                        Have questions or need support? Don’t hesitate to reach out. Email us at <a href="mailto:support@creatorsreach.io" style={{ fontWeight: 'bold', color: 'inherit' }}>support@creatorsreach.io</a>, and our dedicated team will be in touch shortly to provide you with the help you need.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


        {/* FAQ Section */}
        <div className="section faq">
          <div className="container w-container">
            <div className="mg-bottom-60px">
              <div className="inner-container-center-540px">
                <div className="center-text">
                  <div className="mg-bottom-16px">
                    <div className="subtitle-2">Have Questions? We’ve Got Answers!</div>
                  </div>
                  <div className="dark-color-text">
                    <h1>Frequently Asked Questions</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-4 w-container">
              <div className="w-layout-grid grid-1-colmn-20px-900px-width">
                
                {/* FAQ Item 1 */}
                <div className="accordion-item-wrapper" onClick={() => toggleAccordion(0)}>
                  <div className="accordion-content-left">
                    <div className="accordion-inner-720px">
                      <div className="accordion-question">
                        <h4 className="accordion-title dark-color-text">What are the key features of our service?</h4>
                      </div>
                      <div className={`accordion-answer ${openAccordion === 0 ? 'open' : ''}`}>
                        <p style={{ display: openAccordion === 0 ? 'block' : 'none' }}>
                          Our service offers powerful tools and features that are designed to improve efficiency, collaboration, and decision-making for your business.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* FAQ Item 2 */}
                <div className="accordion-item-wrapper mg-top-56px" onClick={() => toggleAccordion(1)}>
                  <div className="accordion-content-left">
                    <div className="accordion-inner-720px">
                      <div className="accordion-question">
                        <h4 className="accordion-title dark-color-text">How can I get started?</h4>
                      </div>
                      <div className={`accordion-answer ${openAccordion === 1 ? 'open' : ''}`}>
                        <p style={{ display: openAccordion === 1 ? 'block' : 'none' }}>
                          You can get started by signing up on our platform. Our easy-to-use interface will guide you through the onboarding process step by step.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* FAQ Item 3 */}
                <div className="accordion-item-wrapper mg-top-56px" onClick={() => toggleAccordion(2)}>
                  <div className="accordion-content-left">
                    <div className="accordion-inner-720px">
                      <div className="accordion-question">
                        <h4 className="accordion-title dark-color-text">Can I upgrade or downgrade my plan later?</h4>
                      </div>
                      <div className={`accordion-answer ${openAccordion === 2 ? 'open' : ''}`}>
                        <p style={{ display: openAccordion === 2 ? 'block' : 'none' }}>
                          Yes, you can easily upgrade or downgrade your plan at any time. Just contact our support team, and they'll assist you with the changes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div> {/* End of accordion grid */}
            </div> {/* End of FAQ container */}
          </div> {/* End of section */}
        </div> {/* End of FAQ Section */}

        {/* Fancy Contact Info Section */}
        <div className="contact-us-section">
          <div className="container w-container">
            <div className="contact-card">
              <div className="contact-card-content">
                <div className="icon-and-text">
                  <i className="fas fa-envelope email-icon"></i> {/* Email Icon */}
                  <div className="contact-info">
                    <h4 className="contact-title">Email Us</h4>
                    <a href="mailto:support@creatorsreach.io" className="contact-link">support@creatorsreach.io</a>
                  </div>
                </div>
                <p className="contact-description">We're here to help! Drop us an email, and we'll get back to you shortly.</p>
              </div>
            </div>
          </div>
        </div> {/* End of Contact Info Section */}
        
      </div> {/* End of page-wrapper */}
    </div>
  );
};

export default Contact;
