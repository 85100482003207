import React from 'react';
import Navigation from './Navigation';
import '../HowItWorks.css';
import { FaUserCircle, FaArrowUp, FaSearch, FaHandshake } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const HowItWorks = () => {
  return (
    <div>
                  <Helmet>
            <title>How it Works</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
      <Navigation />

      {/* Steps Section */}
      <div className="hero-section-bg-image how-it-works-section">
        <div className="container w-container">
          <div className="w-layout-grid grid-steps">

            {/* Step 1 */}
            <div className="step-square" style={{ padding: '20px', textAlign: 'left' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <FaUserCircle size={40} color="#5a67d8" />
                <h3 style={{ margin: 0 }}>Step 1: Create Your Profile</h3>
              </div>
              <p style={{ marginTop: '10px' }}>Easily create your profile and get access to 5 brands with our free plan.</p>
            </div>

            {/* Step 2 */}
            <div className="step-square" style={{ padding: '20px', textAlign: 'left' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <FaArrowUp size={40} color="#5a67d8" />
                <h3 style={{ margin: 0 }}>Step 2: Subscribe to a Paid Plan</h3>
              </div>
              <p style={{ marginTop: '10px' }}>Upgrade to one of our paid plans for more opportunities:</p>
              <ul style={{ paddingLeft: '20px', margin: '10px 0 0' }}>
                <li><strong>Basic Plan:</strong> Immediate access to 15+ brands, weekly updates with 5+ new brands.</li>
                <li><strong>Advanced Plan:</strong> Immediate access to 25+ brands, weekly updates with 10+ new brands, including affiliate, influencer, and brand ambassador programs, plus AI-generated pitching emails.</li>
              </ul>
            </div>

            {/* Step 3 */}
            <div className="step-square" style={{ padding: '20px', textAlign: 'left' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <FaSearch size={40} color="#5a67d8" />
                <h3 style={{ margin: 0 }}>Step 3: Browse Handpicked Brands</h3>
              </div>
              <p style={{ marginTop: '10px' }}>Once subscribed, browse curated lists of brands that match your niche, including full brand profiles and collaboration details.</p>
              <ul style={{ paddingLeft: '20px', margin: '10px 0 0' }}>
                <li>Filter brands by influencer/affiliate or brand ambassador program</li>
                <li>Filter brands by niche, location, Instagram followers</li>
                <li>Save your favorite brands, applied programs, and generated emails (upcoming feature)</li>
              </ul>
            </div>

            {/* Step 4 */}
            <div className="step-square" style={{ padding: '20px', textAlign: 'left'}}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <FaHandshake size={40} color="#5a67d8" style={{marginTop: '-15px'}}/>
                <h3 >Step 4: Connect & Collaborate</h3>
              </div>
              <p style={{ marginTop: '10px' }}>With just one click, apply to influencer or brand ambassador programs, use AI-generated personalized emails (available in the Pro plan) to reach out to brands.</p>
              <ul style={{ paddingLeft: '20px', margin: '10px 0 0' }}>
                <li>Apply to influencer and brand ambassador programs quickly and easily</li>
                <li>Generate personalized pitching emails for your selected brands</li>
                <li>Access tips on crafting the perfect pitch</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="section cta" style={{ textAlign: 'center', padding: '60px 0' }}>
        <div className="container w-container" style={{ maxWidth: '900px', margin: '0 auto' }}>
          <div className="cta-content">
            <h3 className="cta-title">Ready to Take the Next Step?</h3>
            <p className="cta-paragraph">
              Don’t miss out on valuable brand collaborations and opportunities to grow your influence. Start your journey today and connect with the brands that match your style.
            </p>
            <a href="/pricing" className="btn-primary w-inline-block" style={{ backgroundColor: '#fff', color: '#000', padding: '12px 30px', borderRadius: '5px' }}>
              Get Started
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
