import React, { useState } from 'react';

function Filters({ filters, setFilters, setFilteredBrands, brands , totalBrands}) {
    const [minFollowers, setMinFollowers] = useState('');
    const [maxFollowers, setMaxFollowers] = useState('');
    const [affiliateProgram, setAffiliateProgram] = useState('all');
    const [brandAmbasadorProgram, setBrandAmbasadorProgram] = useState('all');

    const [instagramUsername, setInstagramUsername] = useState('');
    const [category, setCategory] = useState('all');
    const [location, setLocation] = useState('all');

    const predefinedCategories = ['Fashion and Clothing', 'Jewelry & Accessories', 'Home Decor']; // Add more if needed
    const predefinedLocations = ['United States', 'Canada']; // Add more if needed

    const handleFilter = () => {
        let filtered = brands;

        // Apply followers filter
        if (minFollowers !== '' || maxFollowers !== '') {
            filtered = filtered.filter(brand => {
                const followers = brand.instagram_followers; // Use the correct field for followers
                return (
                    (minFollowers === '' || followers >= parseInt(minFollowers)) &&
                    (maxFollowers === '' || followers <= parseInt(maxFollowers))
                );
            });
        }

        // // Apply website filter
        // if (website !== 'all') {
        //     filtered = filtered.filter(brand => (website === 'yes' ? brand.website : !brand.website));
        // }

        // Apply affiliate program filter
        if (affiliateProgram !== 'all') {
            filtered = filtered.filter(brand => (affiliateProgram === 'yes' ? brand.influencer_collaboration_program_link : !brand.influencer_collaboration_program_link));
        }
        
        if (brandAmbasadorProgram !== 'all') {
            filtered = filtered.filter(brand => (brandAmbasadorProgram === 'yes' ? brand.brand_ambassador_program : !brand.brand_ambassador_program));
        }

        // // Apply ships worldwide filter
        // if (shipsWorldwide !== 'all') {
        //     filtered = filtered.filter(brand => (shipsWorldwide === 'yes' ? brand.ships_worldwide : !brand.ships_worldwide));
        // }

        // Apply Instagram username filter
        if (instagramUsername) {
            filtered = filtered.filter(brand => brand.instagram_username.toLowerCase().includes(instagramUsername.toLowerCase()));
        }

        // Apply category filter
        if (category !== 'all') {
            filtered = filtered.filter(brand => brand.niche === category);
        }

        // Apply location filter
        if (location !== 'all') {
            filtered = filtered.filter(brand => brand.location === location);
        }

        setFilteredBrands(filtered);
    };

    return (
        <div className="filters-container">
            <div className="filter-item">
                <label htmlFor="affiliateProgram">Affiliate/Influencer Program</label>
                <select
                    id="affiliateProgram"
                    value={affiliateProgram}
                    onChange={(e) => setAffiliateProgram(e.target.value)}
                >
                    <option value="all">No-filter</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
            </div>
            <div className="filter-item">
                <label htmlFor="brandAmbasador">Brand Ambassador Program</label>
                <select
                    id="brandAmbasador"
                    value={brandAmbasadorProgram}
                    onChange={(e) => setBrandAmbasadorProgram(e.target.value)}
                >
                    <option value="all">No-filter</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
            </div>
            <div className="filter-item">
                <label htmlFor="category">Niche</label>
                <select
                    id="category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                >
                    <option value="all">Show-all</option>
                    {predefinedCategories.map((cat) => (
                        <option key={cat} value={cat}>{cat}</option>
                    ))}
                </select>
            </div>
            <div className="filter-item">
                <label htmlFor="instagramUsername">Instagram Username</label>
                <input
                    id="instagramUsername"
                    type="text"
                    placeholder="Search by Instagram Username"
                    value={instagramUsername}
                    onChange={(e) => setInstagramUsername(e.target.value)}
                />
            </div>
            <div className="filter-item">
                <label htmlFor="minFollowers">Min Followers</label>
                <input
                    id="minFollowers"
                    type="number"
                    placeholder="Min Followers"
                    value={minFollowers}
                    onChange={(e) => setMinFollowers(e.target.value)}
                />
            </div>

            <div className="filter-item">
                <label htmlFor="maxFollowers">Max Followers</label>
                <input
                    id="maxFollowers"
                    type="number"
                    placeholder="Max Followers"
                    value={maxFollowers}
                    onChange={(e) => setMaxFollowers(e.target.value)}
                />
            </div>

            <div className="filter-item">
                <label htmlFor="location">Location</label>
                <select
                    id="location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                >
                    <option value="all">Show-all</option>
                    {predefinedLocations.map((loc) => (
                        <option key={loc} value={loc}>{loc}</option>
                    ))}
                </select>
            </div>

            <div className="filter-summary">
                Total number of brands: {totalBrands}
            </div>
            
            <button onClick={handleFilter} className="apply-filters-btn">Apply Filters</button>
        </div>
    );
}

export default Filters;
