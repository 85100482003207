// PrivateNavigation.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';

const PrivateNavigation = ({ isActive, toggleMenu }) => {
  const navigate = useNavigate(); // Use useNavigate for redirecting

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log('User signed out');
        localStorage.removeItem('authToken'); // Remove the auth token from localStorage
        toggleMenu(); // Close the menu after logging out
        navigate('/'); // Redirect the user to the login page
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };
  

  return (
    <div className="nav-container">
      <ul className="nav-header-list w-list-unstyled">
      <li className="nav-list-item">
          <Link to="/dashboard" className={`w-nav-link ${isActive('/dashboard')}`} onClick={toggleMenu}>
            Dashboard
          </Link>
        </li>
        <li className="nav-list-item">
          <Link to="/subscriptions" className={`w-nav-link ${isActive('/subscriptions')}`} onClick={toggleMenu}>
            Subscriptions
          </Link>
        </li>
        <li className="nav-list-item">
          <Link to="/account" className={`w-nav-link ${isActive('/account')}`} onClick={toggleMenu}>
            My Account
          </Link>
        </li>
        <li className="nav-list-item">
          <Link to="/about" className={`w-nav-link ${isActive('/about')}`} onClick={toggleMenu}>
            About
          </Link>
        </li>
        <li className="nav-list-item">
          <Link to="/contact" className={`w-nav-link ${isActive('/contact')}`} onClick={toggleMenu}>
            Contact
          </Link>
        </li>
        <li className="nav-list-item">
        <button onClick={handleLogout} className="w-nav-link button-2">Log out</button>
        </li>
      </ul>
    </div>
  );
};

export default PrivateNavigation;
