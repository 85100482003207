import React, { useState } from 'react';
import '../BrandTable.css';
import { SubscriptionPlans } from './Constants';
import { useNavigate } from "react-router-dom";

function BrandTable({ brands }) {
    const subscriptionPlan = localStorage.getItem('subscriptionPlan');
    const navigate = useNavigate();
    const [clickedMessageIndex, setClickedMessageIndex] = useState(null); // Tracks which button was clicked

    const goToGenerateEmailPage = brand => {
        try {
            const params = new URLSearchParams({
                brand_name: brand.instagram_username || brand.brand_name || '',
                user_id: localStorage.getItem('userId'),
            });
            navigate(`/outreach?${params.toString()}`, { target: "_blank" });
        } catch (error) {
            console.error('Error opening outreach window:', error);
        }
    };

    const handleButtonClick = (index) => {
        if (subscriptionPlan !== SubscriptionPlans.PREMIUM) {
            setClickedMessageIndex(index); // Set the clicked button index
            setTimeout(() => setClickedMessageIndex(null), 3000); // Clear the message after 3 seconds
        } else {
            goToGenerateEmailPage(brands[index]); // Navigate for Premium users
        }
    };

    return (
        <div className="brand-table-wrapper">
            <table className="brand-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Image</th>
                        <th>Brand Name</th>
                        <th>Instagram Username</th>
                        <th>Followers</th>
                        <th>Niche</th>
                        <th>Sub Niche</th>
                        <th>Location</th>
                        <th>Website</th>
                        <th>Influencer or Affiliate Program</th>
                        <th>Brand Ambassador Program</th>
                        <th>Email</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {brands.map((brand, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                                <img
                                    src={brand.signed_image_url}
                                    alt={`${brand.brand_name} logo`}
                                />
                            </td>
                            <td>{brand.brand_name}</td>
                            <td>{brand.instagram_username}</td>
                            <td>{brand.instagram_followers_display}</td>
                            <td>{brand.niche}</td>
                            <td>{brand.sub_niche}</td>
                            <td>{brand.location}</td>
                            <td>
                                <a href={brand.website} target="_blank" rel="noopener noreferrer">
                                    {brand.website}
                                </a>
                            </td>
                            <td>
                                {brand.influencer_collaboration_program_link ? (
                                    <button
                                        onClick={() => window.open(brand.influencer_collaboration_program_link, '_blank')}
                                        className="collaboration-link-btn"
                                    >
                                        Apply
                                    </button>
                                ) : (
                                    <span>–</span>
                                )}
                            </td>
                            <td>
                                {brand.brand_ambassador_program ? (
                                    <button
                                        onClick={() => window.open(brand.brand_ambassador_program, '_blank')}
                                        className="collaboration-link-btn"
                                    >
                                        Apply
                                    </button>
                                ) : (
                                    <span>–</span>
                                )}
                            </td>
                            <td>{brand.email}</td>
                            <td>
                                <div style={{ position: 'relative' }}>
                                    <button
                                        className="generate-email-btn"
                                        onClick={() => handleButtonClick(index)} // Handle click
                                        style={{
                                            cursor: subscriptionPlan === SubscriptionPlans.PREMIUM ? 'pointer' : 'not-allowed',
                                        }}
                                    >
                                        Generate Outreach Email
                                    </button>
                                    {clickedMessageIndex === index && (
                                        <div className="click-message">
                                            AI-generated emails are only available in the Premium plan
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default BrandTable;
