import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import { Link } from 'react-router-dom';
import Navigation from '../components/Navigation';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Please check your inbox.');
    } catch (err) {
      // Handle error cases without showing Firebase error codes
      if (err.code === 'auth/user-not-found') {
        setError('No account found with this email address.');
      } else if (err.code === 'auth/invalid-email') {
        setError('Please enter a valid email address.');
      } else {
        setError('Something went wrong. Please try again later.');
      }
    }
  };

  return (
    <div>
      <Navigation />
      <div className="hero-section-bg-image homepage">
        <div className="w-users-userformpagewrap container">
          <div className="w-users-userloginformwrapper form-block">
            <form onSubmit={handlePasswordReset}>
              <div className="w-users-userformheader">
                <h2>Forgot Password</h2>
                <p className="log-in-text">
                  Enter your email address and we'll send you a link to reset your password.
                </p>
              </div>
              <label htmlFor="Email" className="input-label">Email</label>
              <input
                maxLength="256"
                name="Email"
                id="wf-forgot-password-email"
                className="input-form w-input"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className="mg-40px"></div>
              <input type="submit" className="w-users-userformbutton btn-primary _2 up w-button" value="Reset Password" />
              
              {/* Error and Success Messages */}
              {error && <p style={{ color: 'red' }}>{error}</p>}
              {message && <p style={{ color: 'green' }}>{message}</p>}

              {/* Link to Login Page */}
              <div className="w-users-userformfooter form-footer" style={{ textAlign: 'center', marginTop: '20px' }}>
                <span>Remembered your password? </span>
                <Link
                  to="/login"
                  className="back-to-login-link"
                  style={{
                    color: '#007BFF', // Your theme's primary color
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    fontSize: '14px',
                  }}
                >
                  Back to Login
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
