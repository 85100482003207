import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Navigation from './Navigation';
import '../MyAccount.css';
import config from './config';
import { Helmet } from 'react-helmet';

function MyAccount() {
    const [user, setUser] = useState(null);
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cancelMessage, setCancelMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser({
                    displayName: currentUser.displayName || 'User',
                    email: currentUser.email,
                    photoURL: currentUser.photoURL || '/user.png',
                    emailVerified: currentUser.emailVerified,
                });
            } else {
                navigate('/login');
            }
        });

        const fetchSubscriptionData = async () => {
            const userId = localStorage.getItem('userId');
            const authToken = localStorage.getItem('authToken');

            if (!userId || !authToken) {
                navigate('/login');
                return;
            }

            try {
                const response = await fetch(`${config.apiUrl}/api/v1/users/${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    }
                });

                if (response.status === 401) {
                    await signOut(auth);
                    navigate('/login');
                    return;
                }

                if (!response.ok) throw new Error('Failed to fetch subscription data');

                const data = await response.json();
                setSubscriptionData(data);
            } catch (error) {
                setError('Error fetching subscription data');
            } finally {
                setLoading(false);
            }
        };

        fetchSubscriptionData();

        return () => unsubscribe();
    }, [navigate]);

    const cancelSubscription = async () => {
        const confirmation = window.confirm('Are you sure you want to cancel your subscription?');
        if (!confirmation) {
            return; // Exit if the user cancels
        }
        const userId = localStorage.getItem('userId');
        const authToken = localStorage.getItem('authToken');
        try {
            const response = await fetch(`${config.apiUrl}/api/v1/payment/cancel-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify({ user_id: userId })
            });

            if (response.ok) {
                setCancelMessage("Subscription canceled successfully!");
                setSubscriptionData(null);
            } else {
                setCancelMessage("Failed to cancel subscription.");
            }
        } catch (error) {
            setCancelMessage("Error cancelling subscription.");
        }
    };

    const changeSubscription = () => {
        navigate('/subscriptions');
    };

    const isSubscriptionActive = subscriptionData && subscriptionData.subscription_status === 'Active';

    return (
        <div>
            <Helmet>
            <title>My Account</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <Navigation />
            <div className="position-relative">
                <div className="hero-section-bg-image my-account-page">
                    <div className="container w-container">
                        {loading ? (
                            <div className="loading-spinner">
                                <div className="spinner-icon"></div>
                                <p>Loading account details...</p>
                            </div>
                        ) : error ? (
                            <div className="panel">
                                <h2>{error}</h2>
                                <p>Please check your connection or try again later.</p>
                            </div>
                        ) : (
                            <div className="panel">
                                <div className="left-column">
                                    <div className="profile-section">
                                        {user ? (
                                            <>
                                                <img src={user.photoURL} alt="Profile" className="profile-image" />
                                                <h3 className="white">{user.displayName}</h3>
                                                <p className="white">Email: {user.email}</p>
                                                <p className="white">Email Verified: {user.emailVerified ? 'Yes' : 'No'}</p>
                                            </>
                                        ) : (
                                            <p className="white">User not logged in.</p>
                                        )}
                                    </div>
                                </div>
                                <div className="right-column">
                                    <div className="subscription-section">
                                        <h3 className="form-heading white">Subscription Details</h3>
                                        <p className="white">Subscription Status: {subscriptionData?.subscription_status || subscriptionData?.subscription_status}</p>
                                        <p className="white">Subscription Plan: {subscriptionData?.subscription_plan || subscriptionData?.subscription_plan}</p>
                                        {subscriptionData?.subscription_end_timestamp && (
                                        <p className="white">
                                            Subscription End Date: {new Date(subscriptionData.subscription_end_timestamp * 1000).toLocaleDateString()}
                                        </p>
                                        )}                                                       
                                        {subscriptionData?.next_subscription_plan && (
                                            <p className="white">Next Subscription Plan: {subscriptionData.next_subscription_plan}</p>
                                        )}
                                        <div className="subscription-buttons">
                                            <button
                                                onClick={cancelSubscription}
                                                className={`btn ${!isSubscriptionActive ? 'btn-disabled' : 'btn-danger'}`}
                                                disabled={!isSubscriptionActive}
                                                style={{ backgroundColor: !isSubscriptionActive ? '#f8d7da' : '#dc3545', color: '#fff' }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={changeSubscription}
                                                className="btn change-plan-btn"
                                                style={{
                                                    backgroundColor: '#9370DB',
                                                    color: 'white',
                                                    transition: 'transform 0.2s, box-shadow 0.2s',
                                                }}
                                                onMouseOver={(e) => {
                                                    e.target.style.transform = 'scale(1.03)';
                                                    e.target.style.boxShadow = '0 3px 6px rgba(0, 0, 0, 0.15)'; // Softer shadow
                                                }}
                                                onMouseOut={(e) => {
                                                    e.target.style.transform = 'scale(1)';
                                                    e.target.style.boxShadow = 'none';
                                                }}
                                                >
                                                Change Plan
                                                </button>
                                            </div>

                                        {!isSubscriptionActive && (
                                            <p className="cancel-info">No active subscription to be canceled.</p>
                                        )}

                                        {cancelMessage && <p className="white">{cancelMessage}</p>}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyAccount;
