import React, { useState } from 'react';
import Navigation from '../components/Navigation';
import { FaCompass, FaLightbulb, FaHandshake, FaDollarSign } from 'react-icons/fa'; // Importing icons
import config from './config';
import '../About.css';
import { Helmet } from 'react-helmet';

const About = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubscribe = async (event) => {
    event.preventDefault();
    const email = event.target.email.value; // Access email value from form input directly

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    try {
      const response = await fetch(`${config.apiUrl}/api/v1/newsletter/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        setError(''); // Clear any previous errors on success
        event.target.reset(); // Clear the form input
        setSuccess("Subscription successful! Check your email for updates.");
      } else {
        setError('Failed to subscribe. Please try again later.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div>
                  <Helmet>
            <title>About</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
      <Navigation /> {/* Add the navigation here */}
      <div className="page-wrapper">
        
        {/* Hero Section */}
        <div className="position-relative">
          <div className="hero-section-bg-image about">
            <div className="container w-container">
              <div className="mg-bottom-60px">
                <div className="inner-container-center-700px">
                  <div className="transform-2000">
                    <div className="center-text">
                      <div className="dark-color-text">
                        <h1 className="display-1 white">
                          Empowering Micro-Creators for Success
                        </h1>
                        <p className="white">
                          We believe in the power of creativity and the impact micro-creators can have in today's digital world. Our platform bridges the gap between creators and brands, helping creators establish meaningful collaborations and grow their online presence.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* About Sections with Icons */}
              <div className="transform-2000">
                <div className="w-layout-grid grid-2-colmn-1-colmn-gap-32px">
                  
                  {/* Section: Our Mission */}
                  <div className="service-wrapper">
                    <FaCompass size={40} color="#5a67d8" /> {/* Icon */}
                    <h3>Our Mission</h3>
                    <p>
                      Our mission is to empower micro-creators by providing the tools and insights needed to thrive in the competitive creators landscape. We simplify the process of connecting with brands, offering monetization guidance and opportunities to turn your passion into a profitable business.
                    </p>
                  </div>

                  {/* Section: Why We Exist */}
                  <div className="service-wrapper">
                    <FaLightbulb size={40} color="#5a67d8" /> {/* Icon */}
                    <h3>Why We Exist</h3>
                    <p>
                    Social media's growth has empowered creators, yet creators often struggle to connect with brands. We bridge this gap, helping small creators grow and monetize by delivering opportunities directly to them.                    </p>
                  </div>

                </div>
              </div>

              {/* Additional Sections */}
              <div className="transform-2000">
                <div className="w-layout-grid grid-2-colmn-1-colmn-gap-32px">
                  
                  {/* Section: How We Help */}
                  <div className="service-wrapper">
                    <FaHandshake size={40} color="#5a67d8" /> {/* Icon */}
                    <h3>How We Help</h3>
                    <p>
                    Discover the strategies for monetizing your social media presence. With our platform, micro-creators learn how to unlock new streams of income, work with their favorite brands, and grow their audience. Using our handpicked affiliate links and brand ambassador programs, creators can secure collaborations with over 60 brands in one month.
                    Our platform continuousl curates new brands and their programs, making it easy for you to discover the best opportunities.                    </p>
                  </div>
                  {/* Section: Monetize Your Profile */}
                  <div className="service-wrapper">
                    <FaDollarSign size={40} color="#5a67d8" /> {/* Icon */}
                    <h3>Monetize Your Profile</h3>
                    <p>
                      Discover the strategies for monetizing your social media presence. With our guidance, creators learn how to unlock new streams of income, work with their favorite brands, and grow their audience.
                    </p>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>

        {/* Testimonials Section */}
        <div className="section">
          <div className="container w-container">
            <div className="center-text _60">
              <h6 className="testimonial-intro">What Our Users Say About Us</h6>
            </div>
            <div className="mg-60px"></div>
            <div className="w-layout-grid testimonials-grid">
              {[
                "This platform connected me with brands I’d only dreamed of working with. It’s opened doors I never thought possible!",
                "I used to spend hours searching for the right brand collaborations, but now everything I need is in one place! This platform has saved me so much time by gathering brands with excellent collaboration programs all in one spot.",
                "Within just weeks, I monetized my social profile thanks to this amazing tool!",
                "A true game-changer for my creator career! Now I have a steady flow of collaboration offers and brand partnerships.",
                "I used to feel lost approaching brands. Now, I have the confidence and tools to pitch successfully and secure collaborations!",
                "I’ve grown my followers and even landed ambassador roles. I couldn’t be happier!"
            ]
            .map((testimonial, index) => (
                <div key={index} className="flexi-testimonials-card">
                  <div className="features-info">
                    <p className="testimonials-copy">"{testimonial}"</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Call to Action Section */}
        <div className="section cta" style={{ textAlign: 'center', padding: '60px 0' }}>
        <div className="container w-container" style={{ maxWidth: '900px', margin: '0 auto' }}>
        <div className="content-width-medium" style={{ maxWidth: '100%' }}>
            <div className="mg-bottom-16px">
                <h3 className="white-cta-title">Join Our Monthly Newsletter</h3>
            </div>
            <p className="white-paragraph">
                Stay updated with the latest insights, monetization techniques, and opportunities for creators.
            </p>
            <div>
                <form onSubmit={handleSubscribe} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '100%' }}>
                    <input
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        style={{
                            padding: '12px',
                            borderRadius: '5px 0 0 5px',
                            border: '1px solid #ccc',
                            width: '300px',
                            outline: 'none',
                            marginBottom: '0px',
                            marginRight: '5px',
                            maxWidth: '100%',
                        }}
                        required
                    />
                    <button
                        type="submit"
                        style={{
                            padding: '12px 20px',
                            borderRadius: '0 5px 5px 0',
                            border: 'none',
                            backgroundColor: '#fff',
                            color: '#000',
                            cursor: 'pointer',
                        }}
                    >
                        Subscribe
                    </button>
                </form>
                {error && <p style={{ color: 'red', marginTop: '15px' }}>{error}</p>}
                {success && <p style={{ color: 'white', fontSize: '20px', marginTop: '10' }}>{success}</p>}
            </div>
        </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default About;
