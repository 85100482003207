import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navigation from '../components/Navigation';
import '../OutreachEmailGenerator.module.css';
import config from './config';
import { Helmet } from 'react-helmet';


function OutreachEmailGenerator() {
    const authToken = localStorage.getItem('authToken');

    const [formData, setFormData] = useState({
        name: '',
        brandName: '',
        aboutMe: '',
        outreachType: 'creative',
        emailLength: 'short',
        followers: '',
        engagementRate: '',
        instagramUsername: '',
        includeDemographics: false,
        demographicsInfo: '',
        pastProjects: ['']
    });

    const [generatedEmail, setGeneratedEmail] = useState({
        subject: 'Your outreach email subject will appear here...',
        body: 'Your outreach email will appear here...'
    });

    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const brandNameFromQuery = queryParams.get('brand_name') || '';
        setFormData(prevData => ({
            ...prevData,
            brandName: brandNameFromQuery
        }));
    }, [location]);

    const handleInputChange = (e) => {
        const { id, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [id]: type === 'checkbox' ? checked : value
        }));
    };

    const addProjectField = () => {
        setFormData(prevData => ({
            ...prevData,
            pastProjects: [...prevData.pastProjects, '']
        }));
    };

    const handleProjectChange = (index, value) => {
        const newProjects = [...formData.pastProjects];
        newProjects[index] = value;
        setFormData(prevData => ({ ...prevData, pastProjects: newProjects }));
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const target_url = `${config.apiUrl}/api/v1/generate_email`;
            const response = await fetch(target_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();
            setGeneratedEmail({
                subject: data.email_subject || 'Your outreach email subject will appear here...',
                body: data.email_content || 'Your outreach email will appear here...'
            });
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const copyToClipboard = (elementId) => {
        const text = document.getElementById(elementId).value;
        navigator.clipboard.writeText(text).catch((error) => {
            console.error('Error copying text: ', error);
        });
    };

    return (
        <div>
        <Helmet>
        <title>Outreach Email Generator</title>
        <meta name="viewport" content="width=750" />
        </Helmet>
            <Navigation />
            <div className="position-relative">
                <div className="hero-section-bg-image outreach-page">
                    <div className="container w-container">
                        <h2 className="display-1" style={{ color: 'white', textAlign: 'center' }}>Draft Your Outreach Email</h2>

                        <div className="container" style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            maxWidth: '1200px',
                            margin: '20px auto',
                            backgroundColor: '#f5f7fa',
                            borderRadius: '8px',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            padding: '20px'
                        }}>
                            {/* Left Section */}
                            <div className="left-section" style={{ flex: 1, padding: '10px' }}>
                                <h3 className="form-heading" style={{ color: '#333', fontSize: '1.2rem' }}>Basic Information</h3>
                                <div className="form-group">
                                    <label htmlFor="name" style={{ color: '#333' }}>Name:</label>
                                    <input
                                        type="text"
                                        id="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        placeholder="Enter your first name"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="brandName" style={{ color: '#333' }}>Brand Name:</label>
                                    <input
                                        type="text"
                                        id="brandName"
                                        value={formData.brandName}
                                        onChange={handleInputChange}
                                        placeholder="Enter the brand you're pitching to"
                                    />
                                </div>
                                <h3 className="form-heading" style={{ color: '#333' }}>Outreach Preferences</h3>
                                <div className="form-group">
                                    <label htmlFor="outreachType" style={{ color: '#333' }}>Type of Outreach:</label>
                                    <select id="outreachType" value={formData.outreachType} onChange={handleInputChange}>
                                        <option value="creative">Creative</option>
                                        <option value="formal">Formal</option>
                                        <option value="quirky">Quirky</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="emailLength" style={{ color: '#333' }}>Email Length:</label>
                                    <select id="emailLength" value={formData.emailLength} onChange={handleInputChange}>
                                        <option value="short">Short</option>
                                        <option value="long">Long</option>
                                    </select>
                                </div>
                                <h3 className="form-heading" style={{ color: '#333' }}>Social Media Details</h3>
                                <div className="form-group">
                                    <label htmlFor="followers" style={{ color: '#333' }}>Instagram Followers:</label>
                                    <input
                                        type="number"
                                        id="followers"
                                        value={formData.followers}
                                        onChange={handleInputChange}
                                        placeholder="Enter number of followers"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="engagementRate" style={{ color: '#333' }}>Instagram Engagement Rate (%):</label>
                                    <input
                                        type="number"
                                        id="engagementRate"
                                        value={formData.engagementRate}
                                        onChange={handleInputChange}
                                        placeholder="Enter engagement rate percentage"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="instagramUsername" style={{ color: '#333' }}>Instagram Username:</label>
                                    <input
                                        type="text"
                                        id="instagramUsername"
                                        value={formData.instagramUsername}
                                        onChange={handleInputChange}
                                        placeholder="Enter your Instagram username"
                                    />
                                </div>
                                <h3 className="form-heading" style={{ color: '#333' }}>Additional Information</h3>
                                <div className="form-group">
                                    <label htmlFor="aboutMe" style={{ color: '#333' }}>About Me:</label>
                                    <textarea
                                        id="aboutMe"
                                        value={formData.aboutMe}
                                        onChange={handleInputChange}
                                        placeholder="Tell us a bit about yourself..."
                                        rows="4"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="includeDemographics" style={{ color: '#333' }}>Include Demographics Info</label>
                                    <input
                                        type="checkbox"
                                        id="includeDemographics"
                                        checked={formData.includeDemographics}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {formData.includeDemographics && (
                                    <div className="form-group">
                                        <textarea
                                            id="demographicsInfo"
                                            value={formData.demographicsInfo}
                                            onChange={handleInputChange}
                                            placeholder="Example: 70% women, 30% men..."
                                            rows="3"
                                        />
                                    </div>
                                )}
                                {formData.pastProjects.map((project, index) => (
                                    <div key={index} className="form-group">
                                        <textarea
                                            value={project}
                                            onChange={(e) => handleProjectChange(index, e.target.value)}
                                            placeholder="Describe a past project..."
                                            rows="3"
                                        />
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={addProjectField}
                                    style={{
                                        marginBottom: '20px',
                                        backgroundColor: '#007bff',
                                        color: 'white',
                                        padding: '8px 12px',
                                        border: 'none',
                                        borderRadius: '5px',
                                    }}
                                >
                                    Add Another Project
                                </button>
                                <button
                                    onClick={handleSubmit}
                                    disabled={isLoading}
                                    style={{
                                        backgroundColor: isLoading ? '#ccc' : '#4A4A8A',
                                        cursor: isLoading ? 'not-allowed' : 'pointer',
                                        padding: '10px',
                                        width: '100%',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {isLoading ? 'Generating Email...' : 'Generate Email'}
                                </button>
                            </div>
                            {/* Right Section */}
                            <div className="right-section" style={{ flex: 1, marginTop: '20px' }}>
                                <div className="email-preview" style={{ position: 'relative' }}>
                                    <textarea
                                        id="email-subject-text"
                                        readOnly
                                        style={{
                                            width: '100%',
                                            height: '80px',
                                            padding: '10px',
                                            border: '2px dashed #bbb',
                                            borderRadius: '8px',
                                            boxSizing: 'border-box',
                                            fontFamily: 'Courier New, Courier, monospace',
                                            fontSize: '1rem',
                                            color: '#555',
                                        }}
                                        value={generatedEmail.subject}
                                    />
                                <button
                                    onClick={() => copyToClipboard('email-subject-text')}
                                    style={{
                                        position: 'absolute',
                                        top: '5px',
                                        right: '5px',
                                        backgroundColor: '#708090', // Change to your preferred color (green in this case)
                                        color: 'white',
                                        border: 'none',
                                        padding: '3px 4px', // Smaller padding for a smaller button
                                        borderRadius: '2px', // Adjust radius for a smaller rounded look
                                        fontSize: '0.6rem', // Smaller font size
                                        cursor: 'pointer'
                                    }}
                                >
                                    Copy
                                </button>

                                </div>
                                <div className="email-preview" style={{ position: 'relative', marginTop: '20px' }}>
                                    <textarea
                                        id="email-body-text"
                                        readOnly
                                        style={{
                                            width: '100%',
                                            height: '800px',
                                            padding: '10px',
                                            border: '2px dashed #bbb',
                                            borderRadius: '8px',
                                            boxSizing: 'border-box',
                                            fontFamily: 'Courier New, Courier, monospace',
                                            fontSize: '1rem',
                                            color: '#555',
                                        }}
                                        value={generatedEmail.body}
                                    />
                                    <button
                                        onClick={() => copyToClipboard('email-body-text')}
                                        style={{
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                            backgroundColor: '#708090', // Change to your preferred color (green in this case)
                                            color: 'white',
                                            border: 'none',
                                            padding: '3px 4px', // Smaller padding for a smaller button
                                            borderRadius: '2px', // Adjust radius for a smaller rounded look
                                            fontSize: '0.6rem', // Smaller font size
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Copy
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OutreachEmailGenerator;
