import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../components/Navigation';
import { loadStripe } from "@stripe/stripe-js";
import '../Subscriptions.css';
import config from './config';
import { SubscriptionPlans } from '../components/Constants';

const Subscriptions = () => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const API_BASE_URL = config.apiUrl;
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const navigate = useNavigate();

    const getAuthToken = () => localStorage.getItem('authToken');

    useEffect(() => {
        const fetchSubscriptionData = async () => {
            const token = getAuthToken();
            const userId = localStorage.getItem('userId');
            
            if (!token || !userId) {
                navigate('/login');
                return;
            }

            try {
                const response = await fetch(`${API_BASE_URL}/api/v1/users/${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.status === 401) {
                    navigate('/login');
                    return;
                }

                if (!response.ok) throw new Error('Failed to fetch subscription data');

                const data = await response.json();
                setSubscriptionData(data);
            } catch (err) {
                setError('Error fetching subscription data');
            } finally {
                setLoading(false);
            }
        };

        fetchSubscriptionData();
    }, [navigate, API_BASE_URL]);

    const handleSubscription = async (plan, current_plan = null) => {
        const token = getAuthToken();
        if (!token) {
            navigate('/login');
            return;
        }
        
        let target_url = `${API_BASE_URL}/api/v1/payment/create-subscription/`;

        if (plan === SubscriptionPlans.PREMIUM && current_plan === SubscriptionPlans.BASIC) {
            target_url = `${API_BASE_URL}/api/v1/payment/upgrade-subscription/`;
        } else if ([SubscriptionPlans.FREE, SubscriptionPlans.BASIC].includes(plan) && current_plan === SubscriptionPlans.PREMIUM) {
            target_url = `${API_BASE_URL}/api/v1/payment/downgrade-subscription/`;
        } else if (plan === SubscriptionPlans.FREE && current_plan === SubscriptionPlans.BASIC) {
            target_url = `${API_BASE_URL}/api/v1/payment/downgrade-subscription/`;
        }

        if (target_url.includes('downgrade-subscription')) {
            const confirmation = window.confirm('Are you sure you want to downgrade your subscription?');
            if (!confirmation) {
                return; // Exit if the user cancels
            }
        }

        setButtonLoading(plan);
        setResponseMessage('');
        
        try {
            const response = await fetch(target_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ subscription_plan: plan }),
            });

            if (response.status === 401) {
                navigate('/login');
                return;
            }

            if (response.ok) {
                const data = await response.json();
                const { sessionId } = data;

                if (sessionId) {
                    const stripe = await stripePromise;
                    const { error } = await stripe.redirectToCheckout({ sessionId });

                    if (error) {
                        console.error('Stripe redirection error:', error);
                        setResponseMessage('Error redirecting to payment. Please try again.');
                    } else {
                        setResponseMessage('Subscription successfully updated!');
                        window.location.reload();
                    }
                } else {
                    setResponseMessage('Subscription successfully updated!');
                    window.location.reload();
                }
            } else {
                const errorData = await response.json();
                setResponseMessage('Failed to update subscription. Please try again.');
                console.error('Failed to create subscription:', errorData);
            }
        } catch (error) {
            console.error('Error creating subscription:', error);
            setResponseMessage('An error occurred. Please try again.');
        } finally {
            setButtonLoading(null);
        }
    };

    const getButtonLabelAndStyle = (currentPlan, planName, nextPlan) => {
        if (currentPlan === planName) {
            return { label: 'Active Plan', style: 'active-button', disabled: true };
        }
        if (planName === nextPlan) {
            return { label: 'Next Plan', style: 'disabled-button', disabled: true };
        }
        if ((currentPlan === SubscriptionPlans.FREE || nextPlan === SubscriptionPlans.FREE) || (currentPlan === SubscriptionPlans.BASIC && planName === SubscriptionPlans.PREMIUM)) {
            return { label: 'Upgrade', style: 'upgrade-button', disabled: false };
        }
        return { label: 'Downgrade', style: 'downgrade-button', disabled: false };
    };

    const renderNextSubscriptionInfo = (planName) => {
        const nextPlan = subscriptionData?.next_subscription_plan;
        const nextBillingDate = subscriptionData?.subscription_end_timestamp;
        
        if (planName === nextPlan && nextBillingDate) {
            const formattedDate = new Date(nextBillingDate * 1000).toLocaleDateString();
            return (
                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <p className="scheduled-plan-info" style={{ color: '#555', fontWeight: 'bold', fontSize: '14px'}}>
                        Next subscription: {planName} Plan, starting on {formattedDate}
                    </p>
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            <Navigation />
            <div className="hero-section-bg-image price">
                <div className="container w-container">
                    <div className="mg-bottom-60px">
                        <div className="inner-container-center-540px">
                            <div className="center-text">
                                <div className="dark-color-text">
                                    <p className="display-1 white">
                                        Flexible and Affordable Subscription Plans for Every Creator
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {loading ? (
                        <div>Loading subscription details...</div>
                    ) : error ? (
                        <div>Error: {error}</div>
                    ) : (
                        <div className="w-layout-grid pricing-grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            
                            {/* Free Plan */}
                            <div className="pricing-card" style={{ flex: '1', margin: '0 10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minWidth: '280px', marginBottom: '20px' }}>
                                <div className="card-padding">
                                    <div className="top-price-content-card">
                                        <h5 className="pricing-top-header">Free Plan:</h5>
                                    </div>
                                </div>
                                <div className="card-padding">
                                    <div className="top-price-content-card">
                                        <div className="top-header-title">Starting at</div>
                                        <h2>$0.00<span className="highlight-text">/month</span></h2>
                                        <p>Ideal for exploring our product</p>
                                    </div>
                                </div>
                                <div className="price-features-wrapper" style={{flexGrow: 1}}>
                                <div className="card-padding">
                                    <div className="price-features-wrapper">
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2 white">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan">Access to 5 brands open for collaboration</p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2 white">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan">Access to our free monthly newsletter</p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                {renderNextSubscriptionInfo('Free')}
                                <div className="card-padding button" style={{ textAlign: 'center' }}>
                                    <button
                                        className={`btn-primary w-button ${getButtonLabelAndStyle(subscriptionData?.subscription_plan, SubscriptionPlans.FREE, subscriptionData?.next_subscription_plan).style}`}
                                        onClick={() => handleSubscription(SubscriptionPlans.FREE, subscriptionData?.subscription_plan)}
                                        disabled={buttonLoading === SubscriptionPlans.FREE || getButtonLabelAndStyle(subscriptionData?.subscription_plan, SubscriptionPlans.FREE, subscriptionData?.next_subscription_plan).disabled}
                                    >
                                        {buttonLoading === SubscriptionPlans.FREE ? <span>Processing...</span> : getButtonLabelAndStyle(subscriptionData?.subscription_plan, SubscriptionPlans.FREE, subscriptionData?.next_subscription_plan).label}
                                        {buttonLoading === SubscriptionPlans.FREE && <span className="spinner" />}
                                    </button>
                                </div>
                            </div>

                            {/* Basic Plan */}
                            <div className="pricing-card" style={{ flex: '1', margin: '0 10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minWidth: '280px', marginBottom: '20px' }}>
                                <div className="card-padding">
                                    <div className="top-price-content-card">
                                        <h5 className="pricing-top-header">Basic Plan:</h5>
                                    </div>
                                </div>
                                <div className="card-padding">
                                    <div className="top-price-content-card">
                                        <div className="top-header-title">Starting at</div>
                                        <h2>$9.99<span className="highlight-text">/month</span></h2>
                                        <p style={{minHeight: '60px'}}>Ideal for creators ready to land their first brand partnerships</p>
                                    </div>
                                </div>
                                <div className="price-features-wrapper" style={{flexGrow: 1}}>
                                <div className="card-padding">
                                    <div className="price-features-wrapper">
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Immediate access to 15+ brands</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Weekly updates with 5+ brands</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Access to brands with affiliate/influencer programs</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Email notifications when new brands are available</strong></p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                {renderNextSubscriptionInfo('Basic')}
                                <div className="card-padding button" style={{ textAlign: 'center' }}>
                                    <button
                                        className={`btn-primary w-button ${getButtonLabelAndStyle(subscriptionData?.subscription_plan, SubscriptionPlans.BASIC, subscriptionData?.next_subscription_plan).style}`}
                                        onClick={() => handleSubscription(SubscriptionPlans.BASIC, subscriptionData?.subscription_plan)}
                                        disabled={buttonLoading === SubscriptionPlans.BASIC || getButtonLabelAndStyle(subscriptionData?.subscription_plan, SubscriptionPlans.BASIC, subscriptionData?.next_subscription_plan).disabled}
                                    >
                                        {buttonLoading === SubscriptionPlans.BASIC ? <span>Processing...</span> : getButtonLabelAndStyle(subscriptionData?.subscription_plan, SubscriptionPlans.BASIC, subscriptionData?.next_subscription_plan).label}
                                        {buttonLoading === SubscriptionPlans.BASIC && <span className="spinner" />}
                                    </button>
                                </div>
                            </div>

                            {/* Premium Plan */}
                            <div className="pricing-card" style={{ flex: '1', margin: '0 10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minWidth: '280px', marginBottom: '20px' }}>
                                <div className="card-padding">
                                    <div className="top-price-content-card">
                                        <h5 className="pricing-top-header">Premium Plan:</h5>
                                    </div>
                                </div>
                                <div className="card-padding">
                                    <div className="top-price-content-card">
                                        <div className="top-header-title">Starting at</div>
                                        <h2>$14.99<span className="highlight-text">/month</span></h2>
                                        <p style={{minHeight: '60px'}}>For creators aiming to scale their success and unlock exclusive brand opportunities</p>
                                    </div>
                                </div>
                                <div className="price-features-wrapper" style={{flexGrow: 1}}>
                                <div className="card-padding">
                                    <div className="price-features-wrapper">
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Immediate access to 25+ brands</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Weekly updates with 10+ brands</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Access to brands with affiliate/influencer programs</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Access to brand ambassador program opportunities</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>AI personalized brand pitching emails</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                        <div className="checkmark-circle-2">
                                            <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                        </div>
                                        <p className="features-plan"><strong>Access to curated brands for seasonal events and holidays (e.g., Halloween, Christmas, Valentine's Day)</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Email notifications when new brands are available</strong></p>
                                        </div>
                                        {/* <div className="pricing-feature-wrapper last">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan">AI personalized email templates</p>
                                        </div> */}
                                        {/* <div className="pricing-feature-wrapper last">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan">AI personalized direct messages</p>
                                        </div> */}
                                    </div>
                                </div>
                                </div>
                                {renderNextSubscriptionInfo('Premium')}
                                <div className="card-padding button" style={{ textAlign: 'center' }}>
                                    <button
                                        className={`btn-primary w-button ${getButtonLabelAndStyle(subscriptionData?.subscription_plan, SubscriptionPlans.PREMIUM, subscriptionData?.next_subscription_plan).style}`}
                                        onClick={() => handleSubscription(SubscriptionPlans.PREMIUM, subscriptionData?.subscription_plan)}
                                        disabled={buttonLoading === SubscriptionPlans.PREMIUM || getButtonLabelAndStyle(subscriptionData?.subscription_plan, SubscriptionPlans.PREMIUM, subscriptionData?.next_subscription_plan).disabled}
                                    >
                                        {buttonLoading === SubscriptionPlans.PREMIUM ? <span>Processing...</span> : getButtonLabelAndStyle(subscriptionData?.subscription_plan, SubscriptionPlans.PREMIUM, subscriptionData?.next_subscription_plan).label}
                                        {buttonLoading === SubscriptionPlans.PREMIUM && <span className="spinner" />}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {responseMessage && <div style={{ color: responseMessage.includes('successfully') ? 'green' : 'red', textAlign: 'center', marginTop: '20px' }}>{responseMessage}</div>}
                </div>
            </div>
        </div>
    );
};

export default Subscriptions;
