import React from 'react';
import '../Modal.css'; // Include modal-specific styles

function Modal({ content, onClose }) {
    return (
        <div className="modal" onClick={onClose}>
            <div
                className="modal-content"
                onClick={e => e.stopPropagation()} // Prevent closing when clicking inside the modal
            >
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
                <div id="modal-body">{content}</div>
            </div>
        </div>
    );
}

export default Modal;
