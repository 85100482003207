import React, { useState } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import Navigation from '../components/Navigation';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await sendEmailVerification(user);
      setMessageSent(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navigation />
      <div className="hero-section-bg-image homepage">
        <div className="w-users-userformpagewrap container">
          {!messageSent ? (
            <div className="w-users-usersignupformwrapper form-block">
              <form onSubmit={handleSignUp}>
                <div className="w-users-userformheader">
                  <h2>Sign up</h2>
                  <div className="mg-40px"></div>
                </div>
                <label htmlFor="Email" className="field-label">Email</label>
                <input
                  maxLength="256"
                  name="Email"
                  id="wf-sign-up-email"
                  className="input-form w-input"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="mg-20px"></div>
                <label htmlFor="Password" className="field-label">Password</label>
                <input
                  maxLength="256"
                  name="Password"
                  id="wf-sign-up-password"
                  className="input-form w-input"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div className="mg-40px"></div>
                <input
                  type="submit"
                  className="w-users-userformbutton btn-primary _2 up w-button"
                  value={loading ? 'Signing Up...' : 'Sign Up'}
                  disabled={loading}
                />
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <div className="w-users-userformfooter form-footer">
                  <span>Already have an account?</span>
                  <Link
                    to="/login"
                    className="signup-link"
                    style={{
                      color: '#007BFF',
                      textDecoration: 'none',
                      fontWeight: 'bold',
                      fontSize: '14px',
                    }}
                  >
                    Log in
                  </Link>
                </div>
              </form>
            </div>
          ) : (
            <div className="w-users-success-message form-block">
              <h2>Verification Email Sent!</h2>
              <p>Please check your inbox and verify your email to complete the sign-up process.</p>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                <button
                  className="w-users-userformbutton btn-primary _2 up w-button"
                  onClick={() => navigate('/login')}
                >
                  Go to Login
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Signup;
