import React, { useEffect } from 'react';
import Navigation from '../components/Navigation';

const PrivacyPolicy = () => {
  useEffect(() => {
    if (!document.getElementById('termly-jssdk')) {
      const script = document.createElement('script');
      script.id = 'termly-jssdk';
      script.src = 'https://app.termly.io/embed-policy.min.js';
      document.body.appendChild(script);

      script.onload = () => {
        // Apply custom styles to the Termly embed content after it loads
        const style = document.createElement('style');
        style.innerHTML = `
          [name="termly-embed"] * {
            color: #ffffff !important; /* Force all text within the embed to be white */
          }
        `;
        document.head.appendChild(style);
      };
    }

    return () => {
      const existingScript = document.getElementById('termly-jssdk');
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  return (
    <div>
      <Navigation />

      {/* Privacy Policy Background */}
      <div
        className="hero-section-bg-image how-it-works-section"
        style={{
          paddingTop: '220px', // Adjust padding to start below navigation
        }}
      >
        <div className="container w-container">
          <div
            className="privacy-content"
            name="termly-embed"
            data-id="bb091a34-112a-43d4-bf9c-4fda67b35494"
            style={{
              padding: '40px',
              textAlign: 'left',
              backgroundColor: '#cccccc', // Light background for contrast
              borderRadius: '10px',
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
