import React, { useState } from 'react';
import Navigation from '../components/Navigation';
import { Link } from 'react-router-dom';
import '../Home.css';
import config from './config';
import { logEvent, analytics } from "../firebase"; // Ensure correct path to firebase.js
import { Helmet } from 'react-helmet';

const LogButtonClick = () => {
  logEvent(analytics, "get_started_click", { button_name: "Get Started" });
};


const Home = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubscribe = async (event) => {
    event.preventDefault();
    const email = event.target.email.value; // Access email value from form input directly

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    try {
      const response = await fetch(`${config.apiUrl}/api/v1/newsletter/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setError(''); // Clear any previous errors on success
        event.target.reset(); // Clear the form input
        setSuccess("Subscription successful! Check your email for updates.");
      } else {
        setError('Failed to subscribe. Please try again later.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };
  return (
    <div>
      <Helmet>
      <title>Home</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Navigation /> {/* Conditionally render navigation */}
      
      {/* Hero Section */}
      <div className="position-relative">
        <div className="hero-section-bg-image homepage">
          <div className="overlay"></div> {/* Optional overlay for improved contrast */}
          <div className="container w-container">
            <div className="w-layout-grid grid-2-colmn">
              <div className="position-move-0">
                <h1 className="display-1 white medium-title" style={{ textAlign: "left" }}>
                Discover Your Perfect Brand Partner Now!
                </h1>
                <div className="mg-bottom-40px">
                  <div className="light-color-text">
                    <p className="home-text">Land your next brand partnership today! Get up to 60 handpicked brands offering collaborations and affiliate programs, tailored to your niche. Apply directly or use our AI-powered service to craft the perfect pitching emails and secure deals faster.

                    </p>
                  </div>
                  <div className="light-color-text">
                   <p className="home-text">Don’t wait—login today and try it for FREE! Instantly unlock 5 premium fashion brand profiles, no credit card required. Your next partnership is just a login away!
                   </p>
                   </div>
                </div>
                <div className="button-wrapper">
                  <Link to="/login" className="button-2 w-button-login" onClick={LogButtonClick}>Get Started</Link>
                </div>
              </div>
              <div className="hero-image"></div>
            </div>
            <div className="panel">
              <a href="mailto:support@creatorsreach.io" className="email-left-side-wrapper w-inline-block">
                <div className="top-text-label">Drop us a line.</div>
                <div className="hero-email">support@creatorsreach.io</div>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="section why-choose-us">
      <div className="container w-container">
          <h2 className="center-text">Why Choose Us?</h2>
          <div className="w-layout-grid grid-3-colmn">
              <div className="service-box">
                  <h3>Curated Brand Connections</h3>
                  <p>
                      Discover a handpicked selection of fashion brands, chosen for their collaboration potential, helping you connect with the right partners effortlessly.
                  </p>
              </div>
              <div className="service-box">
                  <h3>Intuitive, User-Friendly Platform</h3>
                  <p>
                      Our platform is built for simplicity, letting you search, filter, and engage with brands easily—saving time and keeping collaboration at your fingertips.
                  </p>
              </div>
              <div className="service-box">
                  <h3>Empower Your Influence</h3>
                  <p>
                      With each brand partnership, take your influence to the next level and build a portfolio that reflects your unique journey as a creator.
                  </p>
              </div>
              <div className="service-box">
                  <h3>Stay Ahead with Timely Updates</h3>
                  <p>
                      Access fresh brand opportunities every week, including seasonal favorites for holidays and events, ensuring you never miss out on high-impact collaborations.
                  </p>
              </div>
          </div>
      </div>
  </div>

      {/* Call to Action Section */}
      <div className="section cta" style={{ textAlign: 'center', padding: '60px 0' }}>
      <div className="container w-container" style={{ maxWidth: '900px', margin: '0 auto' }}>
        <div className="content-width-medium" style={{ maxWidth: '100%' }}>
            <div className="mg-bottom-16px">
                <h3 className="white-cta-title">Join Our Monthly Newsletter</h3>
            </div>
            <p className="white-paragraph">
                Stay updated with the latest insights, monetization techniques, and opportunities for creators.
            </p>
            <div>
                <form onSubmit={handleSubscribe} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '100%' }}>
                    <input
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        style={{
                            padding: '12px',
                            borderRadius: '5px 0 0 5px',
                            border: '1px solid #ccc',
                            width: '300px',
                            outline: 'none',
                            marginBottom: '0px',
                            marginRight: '5px',
                            maxWidth: '100%',
                        }}
                        required
                    />
                    <button
                        type="submit"
                        style={{
                            padding: '12px 20px',
                            borderRadius: '0 5px 5px 0',
                            border: 'none',
                            backgroundColor: '#fff',
                            color: '#000',
                            cursor: 'pointer',
                        }}
                    >
                        Subscribe
                    </button>
                </form>
                {error && <p style={{ color: 'red', marginTop: '15px' }}>{error}</p>}
                {success && <p style={{ color: 'white', fontSize: '20px', marginTop: '10' }}>{success}</p>}
            </div>
        </div>
    </div>
    </div>

    </div>
  );
};

export default Home;
