import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Subscriptions.css';
import '../Success.css'

const SuccessAcountCreation = () => {
    const [loading, setLoading] = useState(true); // State to control the spinner
    const navigate = useNavigate();

    useEffect(() => {
        // Set a 4-second delay before hiding the spinner
        const timer = setTimeout(() => {
            setLoading(false);
        }, 4700);

        // Clear the timer if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    const handleProceed = () => {
        navigate('/dashboard'); // Redirect to the dashboard
    };

    return (
        <div className="hero-section-bg-image price">
            <div className="container w-container">
                <div className="inner-container-center-540px">
                    <div className="center-text">
                        {loading ? (
                            // Center the spinner and preparation message
                            <div className="loading-container" style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',   
                                justifyContent: 'center',
                                minHeight: '200px' // Adjust height as needed
                            }}>
                                <div className="spinner-icon"></div>
                                <p className="message-text white" style={{ marginTop: '20px', textAlign: 'center' }}>
                                Preparing your dashboard… Just a few moments before you can start connecting!                                </p>
                            </div>
                        ) : (
                            // Show the success message after loading
                            <>
                                <div className="dark-color-text">
                                    <p className="display-1 white">
                                    Account Creation Successful! 
                                    </p>
                                </div>
                                <p className="message-text white">
                                    Please proceed to your dashboard to start exploring your brands and opportunities                                </p>
                                <div className="button-center">
                                    <button className="button-go-to-dashboard"
                                    style={{
                                        color: '#fff', // Text color
                                        backgroundImage: "url('/images/icons8-checkmark-104-1_1icons8-checkmark-104-1.png'), linear-gradient(135deg, #1304b6, #a61be2 72%)", // Swap order: Image on top
                                        backgroundPosition: '15px center, 0 0', // Image positioned left; gradient covers entire button
                                        backgroundRepeat: 'no-repeat, no-repeat', // Prevent repetition for both layers
                                        backgroundSize: '25px, cover', // Image specific size; gradient covers the button
                                        borderRadius: '8px', // Rounded corners
                                        width: 'fit-content', // Full width
                                        paddingLeft: '55px !important' , // Explicit padding for space between image and text
                                        paddingRight: '10px !important', // Optional: Adjust for symmetry if needed
                                        padding: '20px', // Vertical and right padding
                                        fontFamily: 'Satoshi Medium, sans-serif', // Font family
                                        fontSize: '17px', // Font size
                                        fontWeight: '700', // Font weight
                                        textDecoration: 'none', // No underline
                                        display: 'flex', // Flex layout
                                        justifyContent: 'center', // Center text horizontally
                                        alignItems: 'center', // Center text vertically
                                        transition: 'transform 0.3s, color 0.3s, background-color 0.3s', // Smooth transitions
                                    }}
                                    onClick={handleProceed}>
                                        Go to Dashboard
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessAcountCreation;
