import React, { useEffect } from 'react';
import { analytics, logEvent } from "./firebase";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Login from './components/Login';
import Signup from './components/Signup';
import ForgotPassword from './components/ForgotPassword';
import Subscriptions from './components/Subscriptions';
import HowItWorks from "./components/HowItWorks";
import Dashboard from "./components/Dashboard";
import MyAccount from "./components/MyAccount";
import OutreachEmailGenerator from './components/OutreachEmailGenerator';
import Success from './components/Success';
import SuccessAccountCreation from './components/SuccessAccountCreation';
import Failure from './components/Failure';
import Pricing from './components/Pricing';
import TermsAndServices from './components/TermsAndServices';
import PrivacyPolicy from './components/PrivacyPolicy';
import Footer from './components/Footer';
import TrackPageView from './components/TrackPageView'

function App() {

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`;
    document.head.appendChild(script);

    // Initialize gtag
    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', process.env.REACT_APP_FIREBASE_MEASUREMENT_ID);
    };
  }, []);


  useEffect(() => {
    // Log a page view on app load
    logEvent(analytics, "page_view");
  
    const userAgent = navigator.userAgent; // Define userAgent correctly
  
    // Determine device type
    const deviceType = /Mobile|Tablet/.test(userAgent) ? "Mobile" : "Desktop";  
    // Determine OS
    let os = "Unknown";
    if (/Windows/.test(userAgent)) os = "Windows";
    else if (/Mac OS/.test(userAgent)) os = "Mac OS";
    else if (/Android/.test(userAgent)) os = "Android";
    else if (/iPhone|iPad|iPod/.test(userAgent)) os = "iOS";
    else if (/Linux/.test(userAgent)) os = "Linux";
  
    // Log device info
    logEvent(analytics, "device_info", { device: deviceType, os });
  }, []);
  

  

  return (
    <Router>
      <TrackPageView />
      <Routes>About
        <Route path="/" element={<Home />} />
        <Route path="/index" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/account" element={<MyAccount />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/outreach" element={<OutreachEmailGenerator />} />
        <Route path="/terms-and-conditions" element={<TermsAndServices />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/success" element={<Success />} />
        <Route path="/account-creation-success" element={<SuccessAccountCreation />} />
        <Route path="/failure" element={<Failure />} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
