import React from 'react';
import Navigation from '../components/Navigation';
import '../Subscriptions.css';
import { Helmet } from 'react-helmet';
const Pricing = () => {
    const handleRedirectToLogin = () => {
        window.location.href = '/login'; // Redirect to login page
    };

    return (
        <div>
        <Helmet>
        <title>Pricing</title>
        <meta name="viewport" content="width=750" />
        </Helmet>
            <Navigation />
            <div className="hero-section-bg-image price">
                <div className="container w-container">
                    <div className="mg-bottom-60px">
                        <div className="inner-container-center-540px">
                            <div className="center-text">
                                <div className="dark-color-text">
                                    <p className="display-1 white">
                                        Flexible and Affordable Subscription Plans for Every Creator
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-layout-grid pricing-grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        
                        {/* Free Plan */}
                        <div className="pricing-card" style={{ flex: '1', margin: '0 10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minWidth: '280px', marginBottom: '20px' }}>
                            <div className="card-padding">
                                <div className="top-price-content-card">
                                    <h5 className="pricing-top-header">Free Plan:</h5>
                                </div>
                            </div>
                            <div className="card-padding">
                                <div className="top-price-content-card">
                                    <div className="top-header-title">Starting at</div>
                                    <h2>$0.00<span className="highlight-text">/month</span></h2>
                                    <p>Great for getting started and exploring brand collaboration opportunities</p>
                                </div>
                            </div>
                            <div className="price-features-wrapper" style={{flexGrow: 1}}>
                                <div className="card-padding">
                                    <div className="price-features-wrapper">
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2 white">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan">Access to 5 brands open for collaboration</p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2 white">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan">Access to our free monthly newsletter</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-padding button" style={{ textAlign: 'center' }}>
                                <button
                                    className="btn-primary w-button subscribe-button"
                                    onClick={handleRedirectToLogin}
                                >
                                    Start now
                                </button>
                            </div>
                        </div>

                        {/* Basic Plan */}
                        <div className="pricing-card" style={{ flex: '1', margin: '0 10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minWidth: '280px', marginBottom: '20px' }}>
                            <div className="card-padding">
                                <div className="top-price-content-card">
                                    <h5 className="pricing-top-header">Basic Plan:</h5>
                                </div>
                            </div>
                            <div className="card-padding">
                                <div className="top-price-content-card">
                                    <div className="top-header-title">Starting at</div>
                                    <h2>$9.99<span className="highlight-text">/month</span></h2>
                                    <p style={{minHeight: '60px'}}>Ideal for creators ready to land their first brand partnerships</p>
                                </div>
                            </div>
                            <div className="price-features-wrapper" style={{flexGrow: 1}}>
                                <div className="card-padding">
                                    <div className="price-features-wrapper">
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Immediate access to 15+ brands</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Weekly updates with 5+ brands</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Access to brands with affiliate/influencer programs</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Email notifications when new brands are available</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-padding button" style={{ textAlign: 'center' }}>
                                <button
                                    className="btn-primary w-button subscribe-button"
                                    onClick={handleRedirectToLogin}
                                >
                                    Subscribe
                                </button>
                            </div>
                        </div>

                        {/* Premium Plan */}
                        <div className="pricing-card" style={{ flex: '1', margin: '0 10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minWidth: '280px', marginBottom: '20px' }}>
                            <div className="card-padding">
                                <div className="top-price-content-card">
                                    <h5 className="pricing-top-header">Premium Plan:</h5>
                                </div>
                            </div>
                            <div className="card-padding">
                                <div className="top-price-content-card">
                                    <div className="top-header-title">Starting at</div>
                                    <h2>$14.99<span className="highlight-text">/month</span></h2>
                                    <p style={{minHeight: '60px'}}>For creators aiming to scale their success and unlock exclusive brand opportunities</p>
                                </div>
                            </div>
                            <div className="price-features-wrapper" style={{flexGrow: 1}}>
                                <div className="card-padding">
                                    <div className="price-features-wrapper">
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Immediate access to 25+ brands</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Weekly updates with 10+ brands</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Access to brands with affiliate/influencer programs</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Access to brand ambassador program opportunities</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>AI personalized brand pitching emails</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                        <div className="checkmark-circle-2">
                                            <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                        </div>
                                        <p className="features-plan"><strong>Access to curated brands for seasonal events (e.g., Halloween, Christmas)</strong></p>
                                        </div>
                                        <div className="pricing-feature-wrapper">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan"><strong>Email notifications when new brands are available</strong></p>
                                        </div>
                                        {/* <div className="pricing-feature-wrapper last">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan">AI personalized email templates</p>
                                        </div> */}
                                        {/* <div className="pricing-feature-wrapper last">
                                            <div className="checkmark-circle-2">
                                                <img src="images/checkmark.png" width="12" alt="" className="_100-invert" />
                                            </div>
                                            <p className="features-plan">AI personalized direct messages</p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="card-padding button" style={{ textAlign: 'center' }}>
                                <button
                                    className="btn-primary w-button subscribe-button"
                                    onClick={handleRedirectToLogin}
                                >
                                    Subscribe
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pricing;
