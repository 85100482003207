import React, { useState, useEffect, useCallback } from 'react';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, getRedirectResult, signInWithRedirect } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate, Link } from 'react-router-dom';
import Navigation from '../components/Navigation';
import '../Login.css';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const provider = new GoogleAuthProvider();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await user.reload();
      if (!user.emailVerified) {
        setError('Please verify your email before logging in.');
        return;
      }
      const token = await user.getIdToken();
      localStorage.setItem('authToken', token);
      localStorage.setItem('userId', user.uid);

      navigate('/dashboard');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleRedirectResult = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getRedirectResult(auth);
      if (result) {
        const user = result.user;
        const token = await user.getIdToken();
        localStorage.setItem('authToken', token);
        localStorage.setItem('userId', user.uid);

        const isNewUser = result._tokenResponse?.isNewUser;

        setLoading(false);
        if (isNewUser) {
          navigate('/account-creation-success'); // New user
        } else {
          navigate('/dashboard'); // Existing user
        }
      }
    } catch (error) {
      console.error("Error handling redirect result:", error);
      setError(error.message);
      setLoading(false);
    }
    finally {
      setLoading(false);
    }
  }, [navigate]);


  useEffect(() => {
    const runRedirect = async () => {
      await handleRedirectResult();
    };
    runRedirect();
  }, [handleRedirectResult]);


  const handleGoogleLogin = async () => {
    try {
      setLoading(true);

      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); // Detect mobile devices
      if (isMobile) {
        // Mobile device: Use redirect
        signInWithRedirect(auth, provider);
      } else {
        // Desktop: Use popup
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        const token = await user.getIdToken();
        localStorage.setItem('authToken', token);
        localStorage.setItem('userId', user.uid);
  
        const isNewUser = result._tokenResponse?.isNewUser;
        
        setLoading(false);
        if (isNewUser) {
          navigate('/account-creation-success'); // New user
        } else {
          navigate('/dashboard'); // Existing user
        }
      }
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  
  };

  return (
    <div>
      <Navigation />
      <div className="hero-section-bg-image homepage">
        <div className="w-users-userformpagewrap container">
          <div className="w-users-userloginformwrapper form-block">
            {loading && (
              <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <p>Processing your login...</p>
                <div className="spinner-container">
                  <div className="spinner"></div>
                </div>
              </div>
            )}
            <form onSubmit={handleLogin}>
              <div className="w-users-userformheader">
                <h2>Log in</h2>
                <p className="log-in-text">
                  Log in to unlock your next brand partnerships, discover new opportunities weekly, and start monetizing your content today
                </p>
              </div>
              <label htmlFor="Email" className="input-label">Email</label>
              <input
                maxLength="256"
                name="Email"
                id="wf-log-in-email"
                className="input-form w-input"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className="mg-20px"></div>
              <label htmlFor="Password" className="input-label">Password</label>
              <input
                maxLength="256"
                name="Password"
                id="wf-log-in-password"
                className="input-form w-input"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div className="mg-40px"></div>
              {error && <div style={{ color: 'red' }}>{error}</div>}
              <input
                type="submit"
                className="w-users-userformbutton btn-primary _2 up w-button"
                value="Log In"
                disabled={loading}
              />
            <div className="google-login-container" style={{ textAlign: 'center' }}>
          <button
            className="google-login-button"
            onClick={handleGoogleLogin}
            disabled={loading}
          >
            <img
              src="images/google-logo.svg"
              alt="Google logo"
            />
            Continue With Google
          </button>
        </div>  
              <div className="w-users-userformfooter form-footer">
                <span>Don't have an account?</span>
                <Link to={loading ? null : "/signup"} className="signup-link" style={{ color: '#007BFF', textDecoration: 'none', fontWeight: 'bold' }}>
                  Sign Up With Email
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
