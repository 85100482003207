import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PublicNavigation from './PublicNavigation';
import PrivateNavigation from './PrivateNavigation';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(null); // Set to null to indicate loading
  const location = useLocation();
  const auth = getAuth();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const isActive = (path) => (location.pathname === path ? 'nav-link-active' : 'nav-link-white');

  const handleLogout = useCallback(async () => {
    await signOut(auth);
    setIsLoggedIn(false);
  }, [auth]);

  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        user.getIdToken(true) // Force-refresh the token
          .then(() => setIsLoggedIn(true))
          .catch(() => handleLogout()); // If token refresh fails, log out
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, [auth, handleLogout]);

  return (
    <div className="navigation-transparent-header w-nav">
      <div className="container w-container">
        <div className="nav-content-wrap">
          <Link to={isLoggedIn ? "/dashboard" : "/"} className="nav-logo-brand w-nav-brand">
            <img src="images/logo.png" width="230" alt="Creators Reach" />
          </Link>
          <div className="header-right-col">
            <nav
              role="navigation"
              className={`nav-header-menu w-nav-menu ${menuOpen ? 'menu-open' : ''}`}
            >
              {isLoggedIn === null ? null : isLoggedIn ? (
                <PrivateNavigation isActive={isActive} toggleMenu={toggleMenu} />
              ) : (
                <PublicNavigation isActive={isActive} toggleMenu={toggleMenu} />
              )}
            </nav>
            <div className="hamburger-menu w-nav-button" onClick={toggleMenu}>
              <div className={`hamburger-top-line ${menuOpen ? 'hamburger-active' : ''}`}></div>
              <div className={`hamburger-bottom-line ${menuOpen ? 'hamburger-active' : ''}`}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
