import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Filters from './Filters';
import BrandTable from './BrandTable';
import Modal from './Modal';
import Navigation from '../components/Navigation';
import '../Dashboard.css';
import config from './config';
import { Helmet } from 'react-helmet';

function Dashboard() {
    const [brands, setBrands] = useState([]);
    const [totalBrands, setTotalBrands] = useState([]);
    const [subscriptionPlan, setSubscriptionPlan] = useState(
        localStorage.getItem('subscriptionPlan') || null
    );
    const [filteredBrands, setFilteredBrands] = useState([]);
    const [filters, setFilters] = useState({});
    const [fields, setFields] = useState([]);
    const [modalContent, setModalContent] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        const authToken = localStorage.getItem('authToken');

        if (!userId || !authToken) {
            navigate('/login');
            return;
        }

        async function fetchBrands() {
            try {
                const controller = new AbortController();
                const timeoutId = setTimeout(() => controller.abort(), 10000);
                const response = await fetch(`${config.apiUrl}/api/v1/brands/${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    },
                    signal: controller.signal
                });

                clearTimeout(timeoutId);

                // Check for 401 Unauthorized
                if (response.status === 401) {
                    navigate('/login');
                    return;
                }

                if (!response.ok) {
                    throw new Error('Failed to fetch brands');
                }

                const data = await response.json();
                setBrands(data.brands);
                setTotalBrands(data.total_brands);
                setFilteredBrands(data.brands);
                setSubscriptionPlan(data.subscription_plan);
                localStorage.setItem('subscriptionPlan', data.subscription_plan);


                if (data.brands.length > 0) {
                    setFields(Object.keys(data.brands[0]));
                } else {
                    alert('No brand data available.');
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    setError('Request timed out');
                } else {
                    setError('Error fetching brands');
                    console.error('Error fetching brands:', error);
                }
            } finally {
                setLoading(false);
            }
        }

        fetchBrands();
    }, [navigate, subscriptionPlan]);

    return (
        <div>
        <Helmet>
        <title>Brand Dashboard</title>
        <meta name="viewport" content="width=850, initial-scale=0.45" />
        </Helmet>   
            <Navigation />
            <div className="position-relative">
                <div className="hero-section-bg-image dashboard-page">
                    <div className="container w-container">
                        <div className="dashboard-header">
                            <h1 className="display-1 white">Brand Dashboard</h1>
                            <p className="light-color-text">
                                Manage your brand data, filter brands, and explore collaborations.
                            </p>
                        </div>

                        {loading ? (
                            <div className="loading-spinner">
                                <div className="spinner-icon"></div>
                                <p>Loading brands...</p>
                            </div>
                        ) : error ? (
                            <div className="panel">
                                <h2>{error}</h2>
                                <p>Please check your connection or try again later.</p>
                            </div>
                        ) : brands.length > 0 ? (
                            <>
                                <Filters
                                    brands={brands}
                                    totalBrands={totalBrands}
                                    fields={fields}
                                    filters={filters}
                                    setFilters={setFilters}
                                    setFilteredBrands={setFilteredBrands}
                                    buttonStyle={{ fontSize: '12px', padding: '4px 10px' }}
                                />
                                <div className="center-content">
                                    <BrandTable
                                        brands={filteredBrands}
                                        fields={fields}
                                        setModalContent={setModalContent}
                                    />
                                </div>
                                {modalContent && (
                                    <Modal
                                        content={modalContent}
                                        onClose={() => setModalContent(null)}
                                    />
                                )}
                            </>
                        ) : (
                            <div className="panel">
                                <h2>No brand data available.</h2>
                                <p>Please check again later or try updating your filters.</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Dashboard;
